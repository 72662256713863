$(document).ready(function () {
	//main navigation

	$('[data-toggle="slide-collapse"]').on('click', function () {
		$(".navbar-toggler").toggleClass("expanded");
		$(".navbar-collapse").toggleClass("show");
		$(".navbar-nav").toggleClass("active");
		$(".navbar-collapse").toggleClass("active");
		//$(".dropdown-menu").toggleClass("active").show();
		//$(".dropdown-menu").toggleClass("show");
	});

	// Close 2nd level dropdown on click
	$('li.nav-item').on('click', function () {
		$(this).find(".dropdown-menu").toggleClass("show");
	});

	// Closes all menus on click
	$('.dropdown-item').on('click', function () {
		$(".navbar-toggler").toggleClass("expanded");
		$(".navbar-nav").toggleClass("active");
		$(".navbar-collapse").toggleClass("active");
	});
	$('.hamburger').on('click', function () {

		$('.animated-icon').toggleClass('open');
	});
	// END of Main navigation


	// For navigation to make parent element clickable
	jQuery(function ($) {
		$('.nav .dropdown').hover(function () {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();

		}, function () {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();

		});

		$('.nav .dropdown > a').click(function () {
			location.href = this.href;
		});

	});
// End of navigation fix
	$(".navbar-toggler").on("click", function () {
		$(this).toggleClass("active");
	});
});